import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUrl, setUserDetails } from "../Redux/action";
import tw from "tailwind-styled-components";
import Onboarding from "../Components/Checkout/Onboarding";
import Payment from "../Components/Checkout/Payment";
import VerifyIdentity from "../Components/Checkout/VerifyIdentity";
import Footer from "../Components/Checkout/Footer";
import Header from "../Components/Checkout/Header";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import Config from "../Config";
import NotFoundModel from "../Components/NotFoundModel";
import SkeletonLoader from "../Components/SkeletonLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Ekyc = () => {
  const [step, setStep] = useState(1);
  const [showOtpField, setShowOtpField] = useState(false);
  const [docId, setDocId] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window.location.href;
    dispatch(setUrl(currentUrl));
  }, [dispatch]);

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);
  const gId = useSelector((state) => state.UserReducer.gId);
  const token = useSelector(
    (state) => state.UserReducer.userDetails?.data?.data?.token
  );

  const detailFunction = async () => {
    if (!gId) return;
    return await axios.get(`${Config.ApiUrl}/tenant/checkin/details/${gId}`);
  };

  const {
    data,
    error,
    refetch,
    isLoading: InitialLoading,
  } = useQuery(`getTenantDetail`, detailFunction, {
    enabled: !!gId,
    onSuccess: (data) => {
      dispatch(setUserDetails(data));
    },
  });

  useEffect(() => {
    if (gId) {
      refetch();
    }
  }, [gId, refetch]);
  // User Details end

  // Number Aadhar start
  const AddAadharNumberFunction = async (values) => {
    return await axios.post(`${Config.ApiUrl}/tenant/kyc/add/id`, values, {
      headers: {
        authorization: "Bearer" + " " + token,
        "Content-Type": "application/json",
      },
    });
  };

  const onAddAadharSuccess = (data) => {
    if (data?.data?.isSuccess === true) {
      toast.success(data?.data?.msg || "Success");
      setDocId(data?.data?.docId);
      setRequestId(data?.data?.requestId);
      setShowOtpField(true);
    }
  };

  const onAddAadharError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: AddAadharLoading, mutate: postAddAadhar } = useMutation(
    AddAadharNumberFunction,
    {
      onSuccess: onAddAadharSuccess,
      onError: onAddAadharError,
    }
  );

  // Number Aadhar end

  // Aadhar OTP start

  const AddAadharOTPFunction = async (values) => {
    return await axios.post(`${Config.ApiUrl}/tenant/kyc/verify/id`, values, {
      headers: {
        authorization: "Bearer" + " " + token,
        "Content-Type": "application/json",
      },
    });
  };

  const onAddAadharOTPSuccess = (data) => {
    if (data?.data?.isSuccess === true) {
      toast.success(data?.data?.msg || "Success");
      navigate("/complete-profile", {
        state: { responseData: data.data.tenant },
      });
    }
  };

  const onAddAadharOTPError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: AddAadharOTPLoading, mutate: postAddAadharOTP } =
    useMutation(AddAadharOTPFunction, {
      onSuccess: onAddAadharOTPSuccess,
      onError: onAddAadharOTPError,
    });

  // Aadhar OTP end

  // Aadhar Upload start

  const AddAadharUploadFunction = async (values) => {
    const body = new FormData();

    values.aadhaarNumber.forEach((file, index) => {
      body.append("files", file);
    });
    body.append("type", Config.Verification_Type.Aadhar);

    return await axios.post(`${Config.ApiUrl}/tenant/kyc/upload/id`, body, {
      headers: {
        authorization: "Bearer" + " " + token,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const onUploadAadharSuccess = (data) => {
    if (data?.data?.isSuccess === true) {
      toast.success(data?.data?.msg || "Success");
      navigate("/complete-profile", {
        state: { responseData: data.data.tenant },
      });
    }
  };

  const onUploadAadharError = (data) => {
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: UploadAadharLoading, mutate: postUploadAadhar } =
    useMutation(AddAadharUploadFunction, {
      onSuccess: onUploadAadharSuccess,
      onError: onUploadAadharError,
    });

  // Aadhar Upload end

  if (error) {
    return <NotFoundModel />;
  }
  return (
    <>
      <BgColor>
        <Container>
          <Main>
            {InitialLoading ? (
              <SkeletonLoader />
            ) : (
              <>
                <Header HeaderData={data?.data?.data} />
                <Onboarding RoomData={data?.data?.data?.occupancy} />
                {step === 1 && (
                  <Payment nextStep={nextStep} PayData={data?.data?.data} />
                )}
                {step === 2 && (
                  <VerifyIdentity
                    prevStep={prevStep}
                    Verification={data?.data?.data?.occupancy}
                    postAddAadhar={postAddAadhar}
                    AddAadharLoading={AddAadharLoading}
                    UploadAadharLoading={UploadAadharLoading}
                    postUploadAadhar={postUploadAadhar}
                    showOtpField={showOtpField}
                    setShowOtpField={setShowOtpField}
                    postAddAadharOTP={postAddAadharOTP}
                    AddAadharOTPLoading={AddAadharOTPLoading}
                    docId={docId}
                    requestId={requestId}
                    setDocId={setDocId}
                    setRequestId={setRequestId}
                  />
                )}
                <Footer />
              </>
            )}
          </Main>
        </Container>
      </BgColor>
    </>
  );
};

const Container = tw.div`md:max-w-sm w-full mx-auto `;
const Main = tw.div`flex flex-col `;
const BgColor = tw.div`bg-zinc-100 max-h-screen min-h-fit md:min-h-screen md:max-h-fit`;

export default Ekyc;
