// action.js

// Action Types
export const SET_URL = "SET_URL";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_VERIFY_PAYMENT = "SET_VERIFY_PAYMENT";

// Action creator for setting the URL
export const setUrl = (url) => ({
  type: SET_URL,
  payload: url,
});

// Action creator for setting the user details
export const setUserDetails = (details) => ({
  type: SET_USER_DETAILS,
  payload: details,
});

// Action creator for setting the Verify Payment
export const setVerifyPayment = (verify) => ({
  type: SET_VERIFY_PAYMENT,
  payload: verify,
});
