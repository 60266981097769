import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import tw from 'tailwind-styled-components';

const Onboarding = ({ RoomData }) => {

  return (
    <CardContainer>
      <div className="flex justify-between">
        <div>
          <SubTitle>{RoomData?.propName}</SubTitle>
          <Location>
            <MdLocationOn className="mr-1 mt-0.5" />
            {RoomData?.propertyStreetAddress},{" "}{RoomData?.propertyAddress}
          </Location>
        </div>
      </div>
      <RoomDetail>
        <RoomNo>Room {RoomData?.roomNum}</RoomNo>
        <RoomType>Floor {RoomData?.floor}</RoomType>
      </RoomDetail>
      <Seperator />
      <RentDetail>
        <Rent>
          <Heading>Rent</Heading>
          <SubHeading>₹{RoomData?.rent}</SubHeading>
        </Rent>
        <Rent>
          <Heading>Security</Heading>
          <SubHeading>₹{RoomData?.security}</SubHeading>
        </Rent>
        <Rent>
          <Heading>Notice Period</Heading>
          <SubHeading>{RoomData?.noticePeriod} Month</SubHeading>
        </Rent>
      </RentDetail>
      <LeftBlob />
      <RightBlob />
    </CardContainer >
  );
};

const CardContainer = tw.div`relative px-4 py-6 md:p-4 xl:py-6 text-white bg-gradient-to-r from-[#2196f3] via-[#0466c8] to-[#0353a4] w-full`;
const SubTitle = tw.h3`text-white md:text-base text-[1.1rem] font-medium`;
const Location = tw.p`text-white md:text-sm text-[0.95rem] flex`;
const RoomDetail = tw.div`flex gap-4 my-5 md:my-3 items-center`;
const RoomNo = tw.p`text-white md:text-sm text-[0.95rem] font-medium bg-black px-4 py-1.5 md:py-1 rounded-lg text-center items-center `;
const RoomType = tw.p`text-blue-500 bg-white md:text-sm text-[0.95rem] font-medium px-4 py-1.5 md:py-1 rounded-lg text-center items-center`;
const Seperator = tw.div`bg-gray-200 h-[0.1rem]`;
const RentDetail = tw.div`flex justify-between mt-5 md:mt-3`;
const Rent = tw.div`flex flex-col`;
const Heading = tw.p`text-white md:text-xs text-[0.82rem] font-normal`;
const SubHeading = tw.p`text-white md:text-base text-[1.1rem] font-medium`;
const RightBlob = tw.div`absolute top-0 -left-[14%]  md:w-40 md:h-40 w-32 h-32 bg-white opacity-5 rounded-full transform translate-x-12 -translate-y-12`;
const LeftBlob = tw.div`absolute bottom-0 left-[10%] md:left-0 md:w-40 md:h-40 w-32 h-32 bg-white opacity-10 rounded-full transform -translate-x-12 translate-y-12`;
export default Onboarding;
