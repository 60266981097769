import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Checkout/Footer";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import NotFoundModel from "../Components/NotFoundModel";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  fatherName: Yup.string().required("Father's Name is required"),
  dob: Yup.string().required("Date of birth is required"),
  address: Yup.string().required("Address is required"),
});

const genderOptions = [
  { value: "1", label: "Male" },
  { value: "2", label: "Female" },
  { value: "3", label: "Prefer Not to Say" },
];

const occupationOptions = [
  { value: "student", label: "Student" },
  { value: "professional", label: "Professional" },
];

const CompleteProfile = () => {
  // const token = useSelector((state) => state.UserReducer.token);
  const token = useSelector(
    (state) => state.UserReducer.userDetails?.data?.data?.token
  );
  const userDetails = useSelector((state) => state.UserReducer.userDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const responseData = location.state?.responseData;

  const RentAgreement =
    userDetails?.data?.data?.occupancy?.isRentAgreementEnabled;

  const CompleteProfileFunction = async (values) => {
    return await axios.post(
      `${Config.ApiUrl}/tenant/personal-info/add`,
      values,
      {
        headers: {
          authorization: "Bearer" + " " + token,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const onCompleteProfileSuccess = (data) => {
    if (data?.data?.isSuccess === true) {
      toast.success(data?.data?.msg || "Success");

      if (RentAgreement === Config.RENT_AGREEMENT_ENABLED.Disabled) {
        navigate("/rent-agreement");
      } else if (RentAgreement === Config.RENT_AGREEMENT_ENABLED.Enabled) {
        navigate("/payment");
      }
    }
  };

  const onCompleteProfileError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: CompleteProfileLoading, mutate: CompleteProfileMutate } =
    useMutation(CompleteProfileFunction, {
      onSuccess: onCompleteProfileSuccess,
      onError: onCompleteProfileError,
    });

  const SubmitHandler = (values) => {
    const requiredFields = ["gender", "occupation"];

    const missingFields = requiredFields.filter(
      (field) => !values[field]?.value
    );

    if (missingFields.length > 0) {
      toast.error(
        `Please fill in the following required fields: ${missingFields.join(
          ", "
        )}`
      );
      return;
    }
    CompleteProfileMutate({
      gender: Number(values.gender.value),
      occupation: values.occupation.value,
      name: values.name,
      fatherName: values.fatherName,
      dob: moment(values.dob).format("YYYY-MM-DD"),
      email: values.email,
      address: values.address,
      alternateNumber: values.alternateNumber,
    });
  };
  if (!responseData) {
    return <NotFoundModel />;
  }
  return (
    <>
      <BgColor>
        <Container>
          <Main>
            <Wrapper>
              <Heading>Create your Profile</Heading>
              <Subheading>
                Your Aadhaar information has been successfully recorded. Next,
                create your profile to finalize your account.
              </Subheading>

              <Formik
                initialValues={{
                  name: responseData.name || "",
                  fatherName: responseData.fatherName || "",
                  dob: moment(responseData.dob).format("YYYY-MM-DD"),
                  gender:
                    genderOptions.find(
                      (option) => option.value == responseData.gender
                    ) || "",
                  occupation:
                    occupationOptions.find(
                      (option) => option.value == responseData.occupation
                    ) || "",
                  email: responseData.email || "",
                  alternateNumber: responseData.alternateMobile || "",
                  address: responseData.address || "",
                }}
                validationSchema={validationSchema}
                onSubmit={SubmitHandler}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <InputWrapper>
                      <Label htmlFor="name">Name</Label>
                      <Field
                        name="name"
                        className="w-full p-2 border rounded-md"
                        disabled
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error text-red-500"
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Label htmlFor="fatherName">Father's Name</Label>
                      <Field
                        name="fatherName"
                        className="w-full p-2 border rounded-md"
                        disabled={!!responseData.fatherName}
                      />
                      <ErrorMessage
                        name="fatherName"
                        component="div"
                        className="error text-red-500"
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Label htmlFor="dob">Date of Birth</Label>
                      <Field
                        name="dob"
                        type="date"
                        className="w-full p-2 border rounded-md"
                        disabled
                      />
                      <ErrorMessage
                        name="dob"
                        component="div"
                        className="error text-red-500"
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Label htmlFor="gender">Gender</Label>
                      <Select
                        options={genderOptions}
                        name="gender"
                        value={values.gender}
                        onChange={(option) => setFieldValue("gender", option)}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Label htmlFor="occupation">Occupation</Label>
                      <Select
                        options={occupationOptions}
                        name="occupation"
                        value={values.occupation}
                        onChange={(option) =>
                          setFieldValue("occupation", option)
                        }
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Label htmlFor="email">Email</Label>
                      <Field
                        name="email"
                        type="email"
                        className="w-full p-2 border rounded-md"
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Label htmlFor="alternateNumber">Alternate Number</Label>
                      <Field
                        name="alternateNumber"
                        className="w-full p-2 border rounded-md"
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Label htmlFor="address">Address</Label>
                      <Field
                        as="textarea"
                        name="address"
                        className="w-full p-2 border rounded-md"
                        rows="3"
                        disabled={!!responseData.address}
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error text-red-500"
                      />
                    </InputWrapper>

                    <ButtonWrapper>
                      <Button type="submit" disabled={CompleteProfileLoading}>
                        {" "}
                        {CompleteProfileLoading ? (
                          <Loading
                            color="white"
                            width={20}
                            height={20}
                            noPadding={true}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </ButtonWrapper>
                  </Form>
                )}
              </Formik>
            </Wrapper>
          </Main>
          <Footer />
        </Container>
      </BgColor>
    </>
  );
};

const Container = tw.div`md:max-w-sm w-full mx-auto`;
const Main = tw.div`flex flex-col gap-12`;
const BgColor = tw.div`bg-zinc-100 min-h-fit max-h-screen md:min-h-screen md:max-h-fit`;
const Wrapper = tw.div`bg-white p-4  w-full `;
const Heading = tw.div`text-base font-semibold`;
const Subheading = tw.div`text-sm font-normal my-4`;
const Label = tw.p`block font-medium mb-1`;
const InputWrapper = tw.div`my-2`;
const ButtonWrapper = tw.div`mt-4`;
const Button = tw.button`bg-black text-white h-10 text-center rounded-lg w-full`;
export default CompleteProfile;
