import React from "react";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { MdCall, MdPayments } from "react-icons/md";
import Config from "../../Config";
import { FaCheckCircle } from "react-icons/fa";
import { Images } from "../../Images";

const Payment = ({ nextStep, PayData }) => {
  const navigate = useNavigate();
  const KycStatus = PayData?.kycStatus;
  const TotalDues = PayData?.totalDues;

  const handleCheckinClick = () => {
    if (KycStatus === Config.KYC_STATUS.PENDING) {
      nextStep();
    } else if (KycStatus === Config.KYC_STATUS.ID_UPLOADED) {
      navigate("/complete-profile", {
        state: { responseData: PayData },
      });
    } else if (
      KycStatus === Config.KYC_STATUS.PERSONAL_INFO &&
      PayData?.occupancy?.isRentAgreementEnabled ===
      Config.RENT_AGREEMENT_ENABLED.Disabled
    ) {
      navigate("/rent-agreement");
    } else if (KycStatus === Config.KYC_STATUS.SELFI_UPLOADED) {
      navigate("/payment");
    }
    else if (
      KycStatus === Config.KYC_STATUS.PERSONAL_INFO &&
      PayData?.occupancy?.isRentAgreementEnabled ===
      Config.RENT_AGREEMENT_ENABLED.Enabled
    ) {
      navigate("/payment");
    }
    else if (KycStatus === Config.KYC_STATUS.RENT_AGREEMENT_DONE) {
      navigate("/payment");
    }
  };

  const paymentDetails = {
    rent: 0,
    security: 0,
    maintenance: 0,
    electricity: 0,
    registration: 0,
    techCharges: 0,
    other: 0,
    subtotal: 0,
    total: 0,
  };

  const duesLabelMapping = {
    [Config.DUES_TYPES.RENT]: "Rent",
    [Config.DUES_TYPES.SECURITY]: "Security",
    [Config.DUES_TYPES.MAINTENANCE]: "Maintenance",
    [Config.DUES_TYPES.ELECTRICITY]: "Electricity",
    [Config.DUES_TYPES.REGISTRATION]: "Registration",
    [Config.DUES_TYPES.TECH_CHARGES]: "Technical Charges",
    [Config.DUES_TYPES.OTHER]: "Other"
  };

  PayData?.dues?.forEach((due) => {
    if (due.type === Config.DUES_TYPES.RENT) {
      paymentDetails.rent += due.amount;
    } else if (due.type === Config.DUES_TYPES.SECURITY) {
      paymentDetails.security += due.amount;
    } else if (due.type === Config.DUES_TYPES.MAINTENANCE) {
      paymentDetails.maintenance += due.amount;
    } else if (due.type === Config.DUES_TYPES.ELECTRICITY) {
      paymentDetails.electricity += due.amount;
    } else if (due.type === Config.DUES_TYPES.REGISTRATION) {
      paymentDetails.registration += due.amount;
    } else if (due.type === Config.DUES_TYPES.TECH_CHARGES) {
      paymentDetails.techCharges += due.amount;
    } else if (due.type === Config.DUES_TYPES.OTHER) {
      paymentDetails.other += due.amount;
    }

    if (due.type !== Config.DUES_TYPES.OTHER) {
      paymentDetails.subtotal += due.amount;
    }

    paymentDetails.total += due.amount;
    return null;
  });

  return (
    <>
      <Card>
        {TotalDues !== 0 && (
          <>
            <Header>
              <Title className="flex">
                <MdPayments className="mr-2 mt-1.5" /> Payment
              </Title>
            </Header>
            <Details>
              {PayData?.dues?.map((due, index) => (
                <DetailRow key={index}>
                  <Label>{duesLabelMapping[due.type] || "Unknown"}</Label>
                  <Value>₹{due.amount}</Value>
                </DetailRow>
              ))}
              <Divider />

              <DetailRow>
                <Label className="font-bold text-base">Total</Label>
                <Value className="font-bold text-base">
                  ₹{paymentDetails.total}
                </Value>
              </DetailRow>
            </Details>
            <Seperator />
            <InvitedWrapper>
              <Mobile>For any query</Mobile>
              <Invited>
                <MdCall className="mr-1 mt-1" />
                <a href={`tel:${PayData?.occupancy?.ownerMobile}`}>
                  {PayData?.occupancy?.ownerMobile || "N/A"}
                </a>
              </Invited>
            </InvitedWrapper>
          </>
        )}
        {TotalDues === 0 && (
          <Box>
            <Image src={Images.SuccessGif} />
            <Heading>Onboarding Completed!</Heading>
            <SubHeading>
              You have successfully completed the onboarding process. Welcome aboard! We're excited to have you with us.
            </SubHeading>
          </Box>
        )}
        {TotalDues !== 0 && (
          <CheckoutButton onClick={handleCheckinClick}>Checkin</CheckoutButton>
        )}
      </Card>
    </>
  );
};

const Card = tw.div`bg-white p-4 w-full `;
const Header = tw.div`flex items-center justify-between mb-4`;
const Title = tw.h2`text-base font-semibold`;
const Details = tw.div`space-y-2`;
const DetailRow = tw.div`flex justify-between items-center`;
const Label = tw.span``;
const Value = tw.span`text-gray-700 font-medium`;
const Divider = tw.hr`my-2 border-gray-300`;
const Seperator = tw.hr`border-dashed border-gray-300 my-3`;
const InvitedWrapper = tw.div`flex justify-between items-center my-4`;
const Invited = tw.div`text-[#1A86C4] flex font-medium text-sm`;
const Mobile = tw.p`text-sm text-gray-500  `;
const CheckoutButton = tw.button`bg-black h-11 items-center text-center text-white w-full rounded-lg`;
const Box = tw.div` text-green-700 text-center p-4 md:mb-[101px] mb-20`;
const Heading = tw.h4`text-md md:text-[17px] font-semibold`;
const SubHeading = tw.p`text-sm md:text-[15px]  font-normal`;
const IconWrapper = tw.div`flex-shrink-0 justify-center items-center flex`;
const Image = tw.img`w-24 h-24 md:w-44 md:h-44 mx-auto`;

export default Payment;
