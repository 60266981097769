import React, { useState, useRef } from "react";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Checkout/Footer";
import { useSelector } from "react-redux";
import Header from "../Components/Checkout/Header";
import Config from "../Config";
import { MdOutlineFileDownload, MdQrCodeScanner } from "react-icons/md";
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { HiCash } from "react-icons/hi";
import { FaRegCreditCard } from "react-icons/fa";
import { BsArrowUpRightCircleFill, BsBank2 } from "react-icons/bs";
import NotFoundModel from "../Components/NotFoundModel";
import { Images } from "../Images";

const Receipt = () => {
  // const token = useSelector((state) => state.UserReducer.token);
  const token = useSelector(
    (state) => state.UserReducer.userDetails?.data?.data?.token
  );
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate(`/${token}`);
  };
  const paymentTransaction = useSelector(
    (state) => state.UserReducer.verifyPayment
  );
  const paymentReceipt = paymentTransaction?.data?.receipts;
  const userDetails = useSelector((state) => state.UserReducer.userDetails);

  const getPaymentMode = (mode) => {
    if (mode === Config.TRANSACTION_MODES.OFFLINE) {
      return (
        <p className="flex">
          <HiCash className="mr-1 w-5 h-5" />
          Cash
        </p>
      );
    } else if (mode === Config.TRANSACTION_MODES.UPI) {
      return (
        <p className="flex">
          <MdQrCodeScanner className="mr-1 w-5 h-5" />
          UPI
        </p>
      );
    } else if (mode === Config.TRANSACTION_MODES.NET_BANKING) {
      return (
        <p className="flex">
          <BsBank2 className="mr-1 w-5 h-5" />
          Net Banking
        </p>
      );
    } else {
      return (
        <p className="flex">
          <FaRegCreditCard className="mr-1 w-5 h-5" />
          Card
        </p>
      );
    }
  };

  return (
    <>
      <BgColor>
        <Container>
          <Main>
            <Header HeaderData={userDetails?.data?.data} />
            <ReceiptContainer>
              <h2 className="text-base font-medium">Recent Transactions</h2>
              <div className="border border-black w-36 h-px"></div>
              {!paymentReceipt && (
                <div className="items-center md:my-16 my-32">
                  <Image src={Images.NoTransaction} alt="No Transactions" />
                  <p className="text-gray-500 text-base mt-2 text-center">
                    No transaction records found
                  </p>
                </div>
              )}
              {paymentReceipt?.map((receipt, index) => (
                <>
                  <ReceiptItem key={index}>
                    <LeftSection>
                      <ReceiptTitle>{receipt.name}</ReceiptTitle>
                      <ReceiptDate>{receipt.paidDate}</ReceiptDate>
                      <TransactionId>#{receipt.transGId}</TransactionId>
                    </LeftSection>
                    <RightSection>
                      <ReceiptAmount>
                        ₹ {receipt.amount}
                        <BsArrowUpRightCircleFill className="ml-1 mt-0.5 text-red-600 w-5 h-5" />
                      </ReceiptAmount>
                      <PaymentMode>{getPaymentMode(receipt.mode)}</PaymentMode>
                      <ViewLink
                        href={receipt.receipt}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MdOutlineFileDownload className="mr-1 w-5 h-5" />{" "}
                        <span className="underline">Receipt</span>
                      </ViewLink>
                    </RightSection>
                  </ReceiptItem>
                  {index < paymentReceipt.length - 1 && <Seperator />}
                </>
              ))}
            </ReceiptContainer>

            <FooterWrapper>
              <Footer />
            </FooterWrapper>
          </Main>
        </Container>
      </BgColor>
    </>
  );
};

const Container = tw.div`md:max-w-sm w-full mx-auto bg-white h-screen relative overflow-y-auto`;
const Main = tw.div`flex flex-col `;
const BgColor = tw.div`bg-zinc-100 max-h-screen min-h-fit md:min-h-screen md:max-h-fit`;
const ReceiptContainer = tw.div`bg-white px-4 py-2`;
const ReceiptItem = tw.div`flex items-center justify-between  py-2`;
const Seperator = tw.div`border-b border-gray-800 h-px my-1`;
const LeftSection = tw.div`flex flex-col space-y-1`;
const RightSection = tw.div`flex flex-col items-end space-y-1`;
const ReceiptTitle = tw.h3`text-sm font-medium `;
const ReceiptDate = tw.p`text-sm`;
const TransactionId = tw.p`text-sm`;
const ReceiptAmount = tw.span`font-medium text-black text-base flex`;
const ViewLink = tw.a`text-sm text-black text-sm flex items-center font-semibold`;
const PaymentMode = tw.span`text-green-600 font-semibold text-sm`;
const Image = tw.img`h-full object-contain mx-auto`;
const ButtonWrapper = tw.div`flex justify-center my-5 `;
const Button = tw.button`bg-black text-white px-4 py-2 rounded-lg font-semibold text-center items-center`;
const FooterWrapper = tw.div`absolute bottom-0 w-full`;
export default Receipt;
