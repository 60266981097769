import React, { useEffect, useState, useRef } from "react";
import { Formik, Form } from "formik";
import tw from "tailwind-styled-components";
import Dropzone from "./Dropzone";
import Config from "../../Config";
import { toast } from "react-toastify";
import AadharLoading from "../AadharLoading";

const VerifyIdentity = ({
    prevStep,
    postUploadAadhar,
    UploadAadharLoading,
    postAddAadhar,
    AddAadharLoading,
    Verification,
    showOtpField,
    postAddAadharOTP,
    AddAadharOTPLoading,
    docId,
    requestId,
    setShowOtpField,
}) => {

    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const otpRefs = useRef([]);

    const [uploadedFileFront, setUploadedFileFront] = useState(null);
    const [uploadedFileBack, setUploadedFileBack] = useState(null);

    const handleFileDropFront = (acceptedFiles) => {
        setUploadedFileFront(acceptedFiles[0]);
    };

    const handleFileDropBack = (acceptedFiles) => {
        setUploadedFileBack(acceptedFiles[0]);
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (value.length === 1 && /^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < 5) {
                otpRefs.current[index + 1].focus();
            }
        } else if (value === "") {
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };

    const isOtpComplete = otp.every((value) => value.length === 1);

    const handleSubmit = (values) => {
        const isImage = (file) => {
            const acceptedImageTypes = 'image/png, image/jpg, image/webp, image/jpeg';
            return file && acceptedImageTypes.includes(file.type);
        };
        if (values.aadhaarOption === "upload") {
            if (uploadedFileFront) {

                if (!isImage(uploadedFileFront)) {
                    toast.error("Please upload an image (jpg, jpeg, or png) for the front side of the Aadhaar card.");
                    return;
                }


                if (uploadedFileBack && !isImage(uploadedFileBack)) {
                    toast.error("Please upload an image (jpg, jpeg, or png) for the back side of the Aadhaar card.");
                    return;
                }

                // if (uploadedFileFront) {
                const uploadValues = {
                    aadhaarNumber: [uploadedFileFront, uploadedFileBack || null],
                };
                postUploadAadhar(uploadValues);
            } else {
                toast.error("Please upload the front side of the Aadhaar card.");
            }
        } else if (values.aadhaarNumber && !showOtpField) {
            postAddAadhar({
                IdNumber: values.aadhaarNumber.toString(),
                type: Config.Verification_Type.Aadhar
            });
        } else if (showOtpField && isOtpComplete) {
            postAddAadharOTP({
                otp: otp.join(""),
                docId,
                type: Config.Verification_Type.Aadhar,
                requestId,
            });
        } else {
            toast.error("Please fill in all required fields.");
        }
    };

    const handleBack = () => {
        setOtp(["", "", "", "", "", ""]);
        setShowOtpField(false);
        prevStep();
    };

    return (
        <Wrapper>

            {(UploadAadharLoading || AddAadharLoading || AddAadharOTPLoading) && (
                <FullPageOverlay>
                    <AadharLoading />
                </FullPageOverlay>
            )}

            <Heading>Verify Your Aadhar Card</Heading>
            <Formik
                initialValues={{
                    aadhaarOption:
                        Verification?.isIdVerificationEnabled ===
                            Config.Verification_Enabled.AadharPhoto
                            ? "upload"
                            : "aadhaarNumber",
                    aadhaarNumber: '',
                }}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange }) => (
                    <Form>
                        {values.aadhaarOption === "upload" ? (
                            <>
                                <InputWrapper>
                                    <Label>Front side*</Label>
                                    <Dropzone
                                        onDrop={handleFileDropFront}
                                        uploadedFile={uploadedFileFront}
                                    />
                                    {uploadedFileFront && (
                                        <ImageName>{uploadedFileFront.name}</ImageName>
                                    )}
                                </InputWrapper>

                                <InputWrapper>
                                    <Label>Back side*</Label>
                                    <Dropzone
                                        onDrop={handleFileDropBack}
                                        uploadedFile={uploadedFileBack}
                                    />
                                    {uploadedFileBack && (
                                        <ImageName>{uploadedFileBack.name}</ImageName>
                                    )}
                                </InputWrapper>
                            </>
                        ) : (
                            <>
                                <InputWrapper>
                                    <Label>Aadhaar Number*</Label>
                                    <input
                                        type="number"
                                        name="aadhaarNumber"
                                        value={values.aadhaarNumber}
                                        onChange={handleChange}
                                        placeholder="Enter Aadhaar Number"
                                        className="w-full p-2 border rounded-md"
                                        required
                                    />
                                </InputWrapper>

                                {showOtpField && (
                                    <InputWrapper>
                                        <Label>Enter OTP*</Label>
                                        <div className="flex gap-2 justify-between">
                                            {otp.map((value, index) => (
                                                <input
                                                    key={index}
                                                    type="number"
                                                    maxLength="1"
                                                    className="w-10 h-10 text-center border rounded-md"
                                                    value={value}
                                                    onChange={(e) => handleOtpChange(e, index)}
                                                    ref={(el) => (otpRefs.current[index] = el)}
                                                />
                                            ))}
                                        </div>
                                    </InputWrapper>
                                )}
                                {/* {!showOtpField && ( */}
                                <AadharDescriptionBox>
                                    <AadharDescription> In order to proceed with the tenant verification process, we need to collect your Aadhaar number. Once you provide this information, an OTP will be sent to the mobile number that is linked with your Aadhaar account.</AadharDescription>
                                </AadharDescriptionBox>
                                {/* )} */}
                            </>
                        )}

                        <ButtonWrapper>
                            <BackButton type="button" onClick={handleBack}>
                                Previous
                            </BackButton>

                            <Button type="submit">

                                {values.aadhaarOption === "upload" ? "Upload" : showOtpField ? "Submit" : "Next"}
                            </Button>
                        </ButtonWrapper>
                    </Form>
                )}
            </Formik>
        </Wrapper>
    );
};

const Wrapper = tw.div`bg-white p-4 w-full relative`;
const Heading = tw.div`text-base font-semibold mb-4`;
const Button = tw.button`bg-black text-white h-10 px-4 rounded-lg`;
const BackButton = tw.button`hover:text-white text-black h-10 px-4 rounded-lg hover:bg-gray-500`;
const InputWrapper = tw.div`mb-4`;
const Label = tw.p`block font-medium mb-1`;
const ImageName = tw.p`mt-2 text-sm font-medium`;
const ButtonWrapper = tw.div`flex justify-between`;
const FullPageOverlay = tw.div`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`;
const AadharDescription = tw.p`text-sm text-gray-500 `;
const AadharDescriptionBox = tw.div`bg-gray-100 p-4 rounded-lg mb-4`;
export default VerifyIdentity;
