export const Images = {
  BgImage: "./Image/BgImage.svg",
  GreetBg: "./Image/greet.jpg",
  Greeting: "./Image/greeting.png",
  Logo: "./Image/kipinn_logo.png",
  Confetti: "./Image/confetti.svg",
  NotFound: "./Image/record-not-found.png",
  LoadingGif: "./Image/verification.gif",
  SuccessGif: "./Image/success.gif",
  NoTransaction: "./Image/notransaction.svg",
};
