import React from "react";
import tw from "tailwind-styled-components";
import { Images } from "../../Images";

const Header = ({ HeaderData }) => {

    return (
        <div className="bg-white px-4 py-2 xl:py-4 flex justify-between border-b border-gray-200">
            <div>
                <Heading>Hey, <span className="font-medium">{HeaderData?.name}</span></Heading>
                <Subheading>Welcome to <span className="text-[#1A86C4]">Kipinn</span></Subheading>
            </div>
            <Logo src={Images.Logo} />
        </div>
    );
};

const Heading = tw.div`md:text-sm text-[0.95rem] text-black`;
const Subheading = tw.div`md:text-sm text-[0.95rem] font-bold text-gray-600`;
const Logo = tw.img`h-10 w-10`;
export default Header;
