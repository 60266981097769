import React, { useState, useRef } from "react";
import tw from "tailwind-styled-components";
import {
  MdKeyboardBackspace,
  MdLocationOn,
  MdPayments,
  MdQrCodeScanner,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Images } from "../Images";
import { Sheet } from "react-modal-sheet";
import Select from "react-select";
import Footer from "../Components/Checkout/Footer";
import { useSelector } from "react-redux";
import Config from "../Config";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { FaRegCreditCard } from "react-icons/fa";
import PaymentSheetLoader from "../Components/PaymentSheetLoader";
import Loading from "../Components/Loading";
import { setUrl, setUserDetails, setVerifyPayment } from "../Redux/action";
import { useDispatch } from "react-redux";

const Payment = () => {
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const token = useSelector((state) => state.UserReducer.token);
  const token = useSelector(
    (state) => state.UserReducer.userDetails?.data?.data?.token
  );
  const userDetails = useSelector((state) => state.UserReducer.userDetails);
  const RoomData = userDetails?.data?.data?.occupancy;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [charges, setCharges] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [orderId, setOrderId] = useState("");
  const [razorPayKey, setRazorPayKey] = useState("");
  const [payableAmount, setPayableAmount] = useState("");

  const paymentDetails = {
    rent: 0,
    security: 0,
    maintenance: 0,
    electricity: 0,
    registration: 0,
    techCharges: 0,
    other: 0,
    subtotal: 0,
    total: 0,
  };

  const duesLabelMapping = {
    [Config.DUES_TYPES.RENT]: "Rent",
    [Config.DUES_TYPES.SECURITY]: "Security",
    [Config.DUES_TYPES.MAINTENANCE]: "Maintenance",
    [Config.DUES_TYPES.ELECTRICITY]: "Electricity",
    [Config.DUES_TYPES.REGISTRATION]: "Registration",
    [Config.DUES_TYPES.TECH_CHARGES]: "Technical Charges",
    [Config.DUES_TYPES.OTHER]: "Other",
  };

  userDetails?.data?.data?.dues?.map((due) => {
    if (due.type === Config.DUES_TYPES.RENT) {
      paymentDetails.rent += due.amount;
    } else if (due.type === Config.DUES_TYPES.SECURITY) {
      paymentDetails.security += due.amount;
    } else if (due.type === Config.DUES_TYPES.MAINTENANCE) {
      paymentDetails.maintenance += due.amount;
    } else if (due.type === Config.DUES_TYPES.ELECTRICITY) {
      paymentDetails.electricity += due.amount;
    } else if (due.type === Config.DUES_TYPES.REGISTRATION) {
      paymentDetails.registration += due.amount;
    } else if (due.type === Config.DUES_TYPES.TECH_CHARGES) {
      paymentDetails.techCharges += due.amount;
    } else if (due.type === Config.DUES_TYPES.OTHER) {
      paymentDetails.other += due.amount;
    }

    if (due.type !== Config.DUES_TYPES.OTHER) {
      paymentDetails.subtotal += due.amount;
    }

    paymentDetails.total += due.amount;

    return null;
  });

  // Payment Collector Start
  const detailFunction = async () =>
    await axios.get(`${Config.ApiUrl}/tenant/payment/collectors`, {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

  const {
    data,
    error,
    refetch,
    isLoading: InitialLoading,
  } = useQuery(`getCollectorDetail`, detailFunction);
  const PaymentCollector = data?.data?.data;

  // Payment Collector end

  const roleOptions =
    PaymentCollector?.map((user) => ({
      value: user.userId,
      label: user.name,
    })) || [];

  // Offline Payment start
  const OfflinePaymentFunction = async (values) => {
    return await axios.post(`${Config.ApiUrl}/tenant/pay/offline`, values, {
      headers: {
        authorization: "Bearer" + " " + token,
        "Content-Type": "application/json",
      },
    });
  };

  const OfflinePaymentSuccess = (data) => {
    if (data?.data?.isSuccess == true) {
      toast.success(data?.data?.msg || "Success");
      setOtpSent(true);
    }
  };

  const OfflinePaymentError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: OfflinePaymentLoading, mutate: OfflinePaymentMutate } =
    useMutation(OfflinePaymentFunction, {
      onSuccess: OfflinePaymentSuccess,
      onError: OfflinePaymentError,
    });

  const handleSendOtp = () => {
    const dueIds = userDetails?.data?.data?.dues?.map((due) => due.id) || [];

    const selectedCollector = {
      userId: selectedRole?.value,
      userType: PaymentCollector.find(
        (user) => user.userId === selectedRole?.value
      )?.userType,
    };

    if (!dueIds || !selectedCollector?.userId || !selectedCollector?.userType) {
      toast.error("Please ensure all required fields are selected.");
      return;
    }

    const payload = {
      dueIds,
      userType: selectedCollector.userType,
      userId: selectedCollector.userId,
    };

    OfflinePaymentMutate(payload);
  };

  // Number Aadhar end

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && /^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  // Payment Collect OTP start

  const VerifyOTPFunction = async (values) => {
    return await axios.post(
      `${Config.ApiUrl}/tenant/pay/offline/otp/verify`,
      values,
      {
        headers: {
          authorization: "Bearer" + " " + token,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const VerifyOTPSuccess = (data) => {
    if (data?.data?.isSuccess == true) {
      toast.success(data?.data?.msg || "Success");
      setPaymentSuccess(true);
      dispatch(setVerifyPayment(data));
      setTimeout(() => {
        navigate(`/receipt`);
      }, 3000);
    }
  };

  const VerifyOTPError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: VerifyOTPLoading, mutate: postVerifyOTP } = useMutation(
    VerifyOTPFunction,
    {
      onSuccess: VerifyOTPSuccess,
      onError: VerifyOTPError,
    }
  );

  // Payment Collect OTP end

  const handleVerifyOtp = () => {
    const otpCode = otp.join("");

    if (otpCode.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }

    const dueIds = userDetails?.data?.data?.dues?.map((due) => due.id) || [];

    const selectedCollector = {
      userId: selectedRole?.value,
      userType: PaymentCollector.find(
        (user) => user.userId === selectedRole?.value
      )?.userType,
    };

    if (
      !dueIds.length ||
      !selectedCollector?.userId ||
      !selectedCollector?.userType
    ) {
      toast.error("Please ensure all required fields are selected.");
      return;
    }

    const payload = {
      otp: otpCode,
      userId: selectedCollector.userId,
      userType: selectedCollector.userType,
      dueIds,
    };

    postVerifyOTP(payload);
  };

  const clearOtpFields = () => {
    setOtp(Array(6).fill(""));
    inputRefs.current.forEach((input) => {
      input.value = "";
    });
  };

  const handleChangeTeam = () => {
    clearOtpFields();
    setOtpSent(false);
  };
  // Order Id Generate start

  const OrderIdFunction = async (values) => {
    return await axios.post(
      `${Config.ApiUrl}/transaction/orderId/generate`,
      values,
      {
        headers: {
          authorization: "Bearer" + " " + token,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const OrderIdSuccess = (data) => {
    if (data?.data?.isSuccess == true) {
      toast.success(data?.data?.msg || "Success");
    }
    setCharges(data?.data?.data?.charges);
    setTotalPayment(data?.data?.data?.totalAmount);
    setOrderId(data?.data?.data?.orderId);
    setRazorPayKey(data?.data?.data?.key);
    setPayableAmount(data?.data?.data?.payableAmount);
  };

  const OrderIdError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: OrderIdLoading, mutate: OrderIdMutate } = useMutation(
    OrderIdFunction,
    {
      onSuccess: OrderIdSuccess,
      onError: OrderIdError,
    }
  );

  const handlePaymentOptionClick = (mode) => {
    const values = {
      mode: Number(mode),
      amount: paymentDetails.total,
    };
    setSelectedPaymentOption(mode);

    OrderIdMutate(values);
  };

  // Order Id Generate end

  // razorpay start

  const handlePayment = async () => {
    const isScriptLoaded = await loadRazorpayScript();

    if (!isScriptLoaded) {
      alert(
        "Failed to load Razorpay SDK. Please check your network connection."
      );
      return;
    }

    const options = {
      key: razorPayKey,
      amount: payableAmount,
      currency: "INR",
      name: "Think Straight IT LLP",
      description: "Kipinn Payment",
      method: {
        netbanking: selectedPaymentOption == "2",
        card: selectedPaymentOption == "3",
        upi: selectedPaymentOption == "1",
        wallet: false,
        paylater: false,
        emi: false,
      },

      handler: async function (response) {
        alert(
          `Payment successful. Payment ID: ${response.razorpay_payment_id}`
        );

        const dueIds =
          userDetails?.data?.data?.dues?.map((due) => due.id) || [];
        const transactionData = {
          mode: Number(selectedPaymentOption),
          amount: payableAmount,
          orderId: orderId,
          key: razorPayKey,
          dueIds,
          status: Config.TRANSACTION_STATUS.SUCCESS,
          paymentId: response.razorpay_payment_id,
        };

        await TransactionMutate(transactionData);
      },

      modal: {
        ondismiss: async function (response) {
          const dueIds =
            userDetails?.data?.data?.dues?.map((due) => due.id) || [];
          const transactionData = {
            mode: Number(selectedPaymentOption),
            amount: payableAmount,
            orderId: orderId,
            key: razorPayKey,
            dueIds,
            status: Config.TRANSACTION_STATUS.FAILED,
            paymentId: "",
          };

          await TransactionMutate(transactionData);
        },
      },

      prefill: {
        name: userDetails?.data?.data?.name,
        email: userDetails?.data?.data?.email,
        contact: userDetails?.data?.data?.mobile,
      },
      notes: {
        address: userDetails?.data?.data?.address,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // razorpay end

  // Transaction start

  const TransactionFunction = async (values) => {
    return await axios.post(`${Config.ApiUrl}/transaction/add`, values, {
      headers: {
        authorization: "Bearer" + " " + token,
        "Content-Type": "application/json",
      },
    });
  };

  const TransactionSuccess = (data) => {
    if (data?.data?.isSuccess == true) {
      toast.success(data?.data?.msg || "Success");
      navigate(`/${token}`);
    }
  };

  const TransactionError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: TransactionLoading, mutate: TransactionMutate } =
    useMutation(TransactionFunction, {
      onSuccess: TransactionSuccess,
      onError: TransactionError,
    });

  // Transaction end

  const openSheet = (mode) => {
    setSelectedPaymentMode(mode);
    setIsModalOpen(true);
  };
  return (
    <>
      <BgColor>
        <Container>
          <Main>
            <div className="flex flex-col ">
              <BgPortion>
                <LeftBlob />
                <div className="flex justify-between">
                  <div>
                    <SubTitle>{RoomData?.propName}</SubTitle>
                    <Location>
                      <MdLocationOn className="mr-1 mt-0.5" />
                      {RoomData?.propertyStreetAddress},{" "}
                      {RoomData?.propertyAddress}
                    </Location>
                  </div>
                </div>
                <RoomDetail>
                  <RoomNo>Room {RoomData?.roomNum}</RoomNo>
                  <RoomType>Floor {RoomData?.floor}</RoomType>
                </RoomDetail>
                <Seperator />
                <RentDetail>
                  <Rent>
                    <RoomHeading>Rent</RoomHeading>
                    <RoomSubHeading>₹{RoomData?.rent}</RoomSubHeading>
                  </Rent>
                  <Rent>
                    <RoomHeading>Security</RoomHeading>
                    <RoomSubHeading>₹{RoomData?.security}</RoomSubHeading>
                  </Rent>
                  <Rent>
                    <RoomHeading>Notice Period</RoomHeading>
                    <RoomSubHeading>
                      {RoomData?.noticePeriod} Month
                    </RoomSubHeading>
                  </Rent>
                </RentDetail>
              </BgPortion>
              <Wrapper>
                <Heading>Payment</Heading>
                <Subheading>Please complete your payment.</Subheading>

                <Card>
                  <Header>
                    <Title className="flex">
                      <MdPayments className="mr-2 mt-1.5" /> Invoice breakdown
                    </Title>
                  </Header>

                  <Details>
                    {userDetails?.data?.data?.dues?.map((due, index) => (
                      <DetailRow key={index}>
                        <Label>{duesLabelMapping[due.type] || "Unknown"}</Label>
                        <Value>₹{due.amount}</Value>
                      </DetailRow>
                    ))}
                    <Divider />

                    <DetailRow>
                      <Label className="font-bold text-base">Total</Label>
                      <Value className="font-bold text-base">
                        ₹{paymentDetails.total}
                      </Value>
                    </DetailRow>
                  </Details>
                </Card>

                <ButtonWrapper>
                  <PayOnline onClick={() => openSheet("online")}>
                    <MdPayments className="mr-2 mt-1" />
                    Pay Online
                  </PayOnline>
                  <PayOffline onClick={() => openSheet("offline")}>
                    Pay Via Cash
                  </PayOffline>
                </ButtonWrapper>
              </Wrapper>
            </div>
          </Main>
          <Footer />
        </Container>
      </BgColor>

      <Sheet
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setOtpSent(false);
          setSelectedRole(null);
          setOtp(["", "", "", "", "", ""]);
        }}
        className="mx-auto max-w-sm"
        detent="content-height"
        disableScrollLocking={true}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            {selectedPaymentMode === "offline" ? (
              <div className="px-4 pb-8">
                {paymentSuccess ? (
                  <div className="text-center">
                    <img
                      src={Images.Confetti}
                      alt="Payment Successful"
                      className="mx-auto mb-4 w-16 h-16"
                    />
                    <h3 className="text-lg font-semibold mb-2">
                      Payment Successful!
                    </h3>
                    <p className="text-sm font-normal mb-4">
                      Your payment has been processed successfully.
                    </p>
                  </div>
                ) : (
                  <>
                    {!otpSent && (
                      <>
                        <h3 className="text-lg font-semibold mb-4">
                          Paying via cash
                        </h3>
                        <p className="text-sm font-normal mb-2">
                          Choose team member to collect cash
                        </p>
                        <Select
                          options={roleOptions}
                          value={selectedRole}
                          onChange={setSelectedRole}
                          placeholder="Select team member"
                        />
                        <ButtonWrappers>
                          <Button
                            onClick={handleSendOtp}
                            disabled={OfflinePaymentLoading}
                          >
                            {" "}
                            {OfflinePaymentLoading ? (
                              <Loading
                                color="white"
                                width={20}
                                height={20}
                                noPadding={true}
                              />
                            ) : (
                              "Send OTP"
                            )}
                          </Button>
                        </ButtonWrappers>
                      </>
                    )}

                    {otpSent && (
                      <>
                        <p className="text-green-500 font-medium text-sm mb-2">
                          OTP sent to the {selectedRole?.label}
                        </p>
                        <div className="mt-4">
                          <label
                            htmlFor="otp"
                            className="block text-sm font-medium"
                          >
                            Enter OTP
                          </label>
                          <div className="flex mt-2 justify-between text-center items-center">
                            {otp.map((value, index) => (
                              <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                type="number"
                                maxLength={1}
                                className="w-10 h-10 text-center border items-center rounded-md"
                                value={value}
                                onChange={(e) => handleOtpChange(e, index)}
                              />
                            ))}
                          </div>
                          <ButtonWrappers>
                            <Button
                              onClick={handleChangeTeam}
                              className="bg-white border border-black text-black flex justify-center"
                            >
                              <MdKeyboardBackspace className="mt-1 mr-2" />
                              Change Team
                            </Button>
                            <Button
                              onClick={handleVerifyOtp}
                              disabled={VerifyOTPLoading}
                            >
                              {VerifyOTPLoading ? (
                                <Loading
                                  color="white"
                                  width={20}
                                  height={20}
                                  noPadding={true}
                                />
                              ) : (
                                "Verify OTP"
                              )}
                            </Button>
                          </ButtonWrappers>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="px-4 py-4">
                {OrderIdLoading && <PaymentSheetLoader />}
                {!OrderIdLoading && (
                  <>
                    <div className="flex justify-between">
                      <div>
                        <DuesAmount>Dues Amount</DuesAmount>
                        <Amount> ₹{paymentDetails.total}</Amount>
                      </div>
                      <div>
                        <DuesAmount>Convenience fees</DuesAmount>
                        <Amount>₹{charges || 0}</Amount>
                      </div>
                    </div>
                    <div className="space-y-4">
                      <h3>Please select a payment method to proceed.</h3>
                      <PaymentOption
                        onClick={() => handlePaymentOptionClick("1")}
                        className={`${
                          selectedPaymentOption === "1"
                            ? "bg-blue-500 text-white"
                            : "bg-white"
                        } cursor-pointer`}
                      >
                        <MdQrCodeScanner className="w-7 h-7" />
                        <div>
                          <OptionLabel>UPI</OptionLabel>
                          <OptionSubLabel>
                            Pay instantly using your UPI ID or app
                          </OptionSubLabel>
                        </div>
                      </PaymentOption>

                      <PaymentOption
                        onClick={() => handlePaymentOptionClick("3")}
                        className={`${
                          selectedPaymentOption === "3"
                            ? "bg-blue-500 text-white"
                            : "bg-white"
                        } cursor-pointer`}
                      >
                        <FaRegCreditCard className="w-7 h-7" />
                        <div>
                          <OptionLabel>Card</OptionLabel>
                          <OptionSubLabel>
                            Use your credit or debit card for a seamless
                            transaction
                          </OptionSubLabel>
                        </div>
                      </PaymentOption>

                      <div className="flex justify-between">
                        <div>
                          <DuesAmount>Total Dues</DuesAmount>
                          <Amount>
                            {" "}
                            ₹{totalPayment || paymentDetails.total}
                          </Amount>
                        </div>
                        {(selectedPaymentOption === "3" ||
                          selectedPaymentOption === "1") && (
                          <div>
                            <PayOnline onClick={handlePayment}>
                              Pay Now
                            </PayOnline>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </>
  );
};
const BgPortion = tw.div`relative bg-gradient-to-r from-[#2196f3] via-[#0466c8] to-[#0353a4] px-4 py-6 md:p-4 xl:py-6 `;
const LeftBlob = tw.div`absolute bottom-0 left-[10%] md:left-0 md:w-40 md:h-40 w-32 h-32 bg-white opacity-10 rounded-full transform -translate-x-12 translate-y-12`;
const ButtonWrapper = tw.div`flex justify-between mt-3`;
const Container = tw.div`md:max-w-sm w-full mx-auto`;
const Main = tw.div`flex flex-col`;
const BgColor = tw.div`bg-zinc-100  min-h-fit max-h-screen md:min-h-screen md:max-h-fit`;
const Wrapper = tw.div`bg-white p-4 w-full  `;
const Card = tw.div`bg-white p-6 rounded-lg shadow-lg w-full  border my-2`;
const Header = tw.div`flex items-center justify-between mb-4`;
const Title = tw.h2`text-base font-semibold`;
const Details = tw.div`space-y-2`;
const DetailRow = tw.div`flex justify-between items-center`;
const Label = tw.span``;
const Value = tw.span`text-gray-700 font-medium`;
const Divider = tw.hr`my-2 border-gray-300`;
const Heading = tw.div`text-base font-semibold`;
const Subheading = tw.div`text-sm font-normal my-2`;
const Button = tw.button`w-full bg-black text-white px-4 py-2 rounded-lg font-semibold text-center`;
const ButtonWrappers = tw.div`flex justify-between my-6 gap-4 `;
const SubTitle = tw.h3`text-white md:text-base text-[1.1rem] font-medium`;
const Location = tw.p`text-white md:text-sm text-[0.95rem] flex`;
const RoomDetail = tw.div`flex gap-4 my-5 md:my-3 items-center`;
const RoomNo = tw.p`text-white md:text-sm text-[0.95rem] font-medium bg-black px-4 py-1.5 md:py-1 rounded-lg text-center items-center `;
const RoomType = tw.p`text-blue-500 bg-white md:text-sm text-[0.95rem] font-medium px-4 py-1.5 md:py-1 rounded-lg text-center items-center`;
const Seperator = tw.div`bg-gray-200 h-[0.1rem]`;
const RentDetail = tw.div`flex justify-between mt-5 md:mt-3`;
const Rent = tw.div`flex flex-col`;
const RoomHeading = tw.p`text-white md:text-xs text-[0.82rem] font-normal`;
const RoomSubHeading = tw.p`text-white md:text-base text-[1.1rem] font-medium`;
const PayOnline = tw.button`bg-black flex text-white px-4 py-2 rounded-lg text-sm font-semibold `;
const PayOffline = tw.button`text-black border border-black text-sm px-4 py-2 rounded-lg font-semibold`;
const PaymentOption = tw.div`flex items-center  border border-gray-300 p-4 rounded-lg space-x-6`;
const OptionLabel = tw.h4`text-base font-semibold`;
const OptionSubLabel = tw.p`text-sm`;
const DuesAmount = tw.p`text-xs text-gray-600 `;
const Amount = tw.p`text-sm font-bold mb-2`;
export default Payment;
