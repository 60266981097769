import React from "react";
import tw from "tailwind-styled-components";

const Footer = () => {

    return (
        <div className="bg-white py-4">
            <Subheading>Kipinn by</Subheading><Heading>Think Straight IT LLP</Heading>
        </div>
    );
};

const Heading = tw.div`text-center text-[0.7rem] text-gray-500`;
const Subheading = tw.div`text-center text-[0.92rem] font-medium text-gray-700`;
export default Footer;
