import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = () => {
  return (
    <div className="bg-white  p-4 max-w-sm w-full">
      <div className="flex items-center justify-between mb-4">
        <div className="flex flex-col">
          <Skeleton width={80} height={16} />
          <Skeleton width={60} height={12} className="mt-1" />
        </div>
        <Skeleton circle={true} height={40} width={40} />
      </div>

      <div className="bg-blue-500 text-white p-4 rounded-lg mb-4">
        <Skeleton width={140} height={18} />
        <Skeleton width={100} height={12} className="mt-1" />

        <div className="flex justify-between mt-4">
          <Skeleton width={70} height={24} />
          <Skeleton width={100} height={24} />
        </div>

        <div className="mt-4">
          <div className="flex justify-between mb-2">
            <Skeleton width={80} height={16} />
            <Skeleton width={40} height={16} />
          </div>
          <div className="flex justify-between mb-2">
            <Skeleton width={80} height={16} />
            <Skeleton width={40} height={16} />
          </div>
          <div className="flex justify-between mb-2">
            <Skeleton width={80} height={16} />
            <Skeleton width={40} height={16} />
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 pt-4 mt-4">
        <Skeleton width={60} height={16} className="mb-2" />
        <div className="flex justify-between mb-2">
          <Skeleton width={80} height={16} />
          <Skeleton width={50} height={16} />
        </div>
        <div className="flex justify-between mb-2">
          <Skeleton width={80} height={16} />
          <Skeleton width={50} height={16} />
        </div>
        <div className="flex justify-between mb-2">
          <Skeleton width={80} height={16} />
          <Skeleton width={50} height={16} />
        </div>
        <div className="flex justify-between mb-2">
          <Skeleton width={80} height={16} />
          <Skeleton width={50} height={16} />
        </div>
        <div className="flex justify-between text-blue-500 font-semibold">
          <Skeleton width={80} height={16} />
          <Skeleton width={50} height={16} />
        </div>
      </div>

      <Skeleton width="100%" height={40} className="mt-4" />

      <div className="text-center text-xs mt-4">
        <Skeleton width={140} height={12} />
      </div>
    </div>
  );
};



export default SkeletonLoader;
