import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PaymentSheetLoader = () => {
    return (
        <div className="space-y-4">
            <div className="flex justify-between">
                <div>
                    <Skeleton width={100} height={20} />
                    <Skeleton width={60} height={30} style={{ marginTop: '5px' }} />
                </div>
                <div>
                    <Skeleton width={100} height={20} />
                    <Skeleton width={60} height={30} style={{ marginTop: '5px' }} />
                </div>
            </div>


            <Skeleton height={70} className="rounded-lg" />
            <Skeleton height={70} className="rounded-lg" />
            <Skeleton height={70} className="rounded-lg" />

            <div className="flex justify-between">
                <div>
                    <Skeleton width={100} height={20} />
                    <Skeleton width={60} height={30} style={{ marginTop: '5px' }} />
                </div>
                <Skeleton width={120} height={40} className="rounded-lg" />
            </div>
        </div>
    );
};

export default PaymentSheetLoader;
