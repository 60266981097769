// const apiUrl = "http://172.208.33.94:3001/api"; //Development
const apiUrl = "https://apis.kipinn.com/api"; //Production
const Config = {
  ApiUrl: apiUrl,

  DUES_TYPES: {
    RENT: 1,
    SECURITY: 2,
    MAINTENANCE: 3,
    ELECTRICITY: 4,
    REGISTRATION: 5,
    TECH_CHARGES: 6,
    OTHER: 7,
  },
  Verification_Enabled: {
    AadharNumber: 1,
    AadharPhoto: 0,
  },
  RENT_AGREEMENT_ENABLED: {
    Disabled: 1,
    Enabled: 0,
  },
  Verification_Type: {
    Aadhar: 1,
  },
  KYC_STATUS: {
    PENDING: 1,
    ID_UPLOADED: 2,
    PERSONAL_INFO: 3,
    SELFI_UPLOADED: 4,
    RENT_AGREEMENT_DONE: 5,
    POLICE_VERIFICATION_DONE: 6,
  },
  TRANSACTION_STATUS: {
    PENDING: 1,
    SUCCESS: 2,
    FAILED: 3,
  },
  TRANSACTION_MODES: {
    OFFLINE: 1,
    UPI: 2,
    NET_BANKING: 3,
    CARD: 4,
  },
};

export default Config;
