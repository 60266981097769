import { useDropzone } from 'react-dropzone';
import tw from 'tailwind-styled-components';
import { useEffect, useState } from 'react';

const Dropzone = ({ onDrop, uploadedFile }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpg, image/webp, image/jpeg',
        maxFiles: 1,
        maxSize: 10000000,
    });

    const [filePreview, setFilePreview] = useState(null);

    useEffect(() => {
        if (uploadedFile) {
            const previewUrl = URL.createObjectURL(uploadedFile);
            setFilePreview(previewUrl);

            return () => URL.revokeObjectURL(previewUrl);
        }
    }, [uploadedFile]);

    return (
        <DropzoneWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p className='text-xs font-medium'>Drop the files here...</p>
            ) : uploadedFile ? (
                <img src={filePreview} alt="Uploaded File" className="h-28 w-28 object-cover rounded-md" />
            ) : (
                <p className='text-xs font-medium'>Click to upload or drag and drop</p>
            )}
        </DropzoneWrapper>
    );
};

const DropzoneWrapper = tw.div`flex flex-col items-center justify-center h-32 bg-gray-100 p-4 border-2 border-dotted rounded-md cursor-pointer`;

export default Dropzone;

