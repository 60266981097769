import React, { useRef } from "react";
import tw from "tailwind-styled-components";
import SignatureCanvas from "react-signature-canvas";
import { GrPowerCycle } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Checkout/Footer";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import Config from "../Config";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import NotFoundModel from "../Components/NotFoundModel";

const ESign = () => {
  const sigCanvas = useRef({});
  const navigate = useNavigate();
  // const token = useSelector((state) => state.UserReducer.token);
  const token = useSelector(
    (state) => state.UserReducer.userDetails?.data?.data?.token
  );
  const RentAgreementFunction = async () =>
    await axios.get(`${Config.ApiUrl}/tenant/agreement-terms`, {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

  const {
    data,
    error,
    isLoading: InitialLoading,
  } = useQuery(`getRentAgreement`, RentAgreementFunction);

  const rentAgreementHtml = data?.data?.data;

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

    const base64String = dataURL.split(",")[1];
    const binaryString = atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: "image/png" });

    return blob;
  };

  const AddSignFunction = async (values) => {
    return await axios.post(
      `${Config.ApiUrl}/tenant/rent-agreement/create`,
      values,
      {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const onAddSignSuccess = (data) => {
    if (data?.data?.isSuccess === true) {
      toast.success(data?.data?.msg || "Success");
      navigate("/payment", {
        state: { responseData: data.data.tenant },
      });
    }
  };

  const onAddSignError = (data) => {
    toast.error(data?.response?.data?.msg || "Error");
  };

  const { isLoading: AddSignLoading, mutate: postSignMutation } = useMutation(
    AddSignFunction,
    {
      onSuccess: onAddSignSuccess,
      onError: onAddSignError,
    }
  );

  const onSubmit = () => {
    if (sigCanvas.current.isEmpty()) {
      toast.error("Signature is required.");
      return;
    }
    const signatureBase64 = saveSignature();
    console.log(signatureBase64.size);

    postSignMutation({ file: signatureBase64 });
  };

  if (error) {
    return <NotFoundModel />;
  }
  return (
    <>
      <BgColor>
        <Container>
          <Main>
            <Wrapper>
              <Heading>Rent Agreement</Heading>
              <Subheading>
                Please sign below to complete your agreement.
              </Subheading>

              {InitialLoading ? (
                <Loading />
              ) : (
                <>
                  <div
                    className="rent-agreement"
                    dangerouslySetInnerHTML={{ __html: rentAgreementHtml }}
                  />
                  <div>
                    <SignWrapper>
                      <Label>Sign here</Label>
                      <ClearIcon onClick={clearSignature}>
                        <GrPowerCycle />
                      </ClearIcon>
                    </SignWrapper>
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        className: "signatureCanvas w-full h-48 border",
                      }}
                    />

                    <div className=" mt-4">
                      <Button onClick={onSubmit} disabled={AddSignLoading}>
                        {" "}
                        {AddSignLoading ? (
                          <Loading
                            color="white"
                            width={20}
                            height={20}
                            noPadding={true}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Wrapper>
          </Main>
          <Footer />
        </Container>
      </BgColor>
    </>
  );
};

const Container = tw.div`md:max-w-sm w-full mx-auto relative`;
const Main = tw.div`flex flex-col `;
const BgColor = tw.div`bg-zinc-100 max-h-screen min-h-fit md:min-h-screen md:max-h-fit`;
const Wrapper = tw.div`bg-white p-4  w-full `;
const Heading = tw.div`text-base font-semibold`;
const Subheading = tw.div`text-sm font-normal my-4`;
const Button = tw.button`bg-black text-white h-10 w-full rounded-lg`;
const SignWrapper = tw.div`h-10 bg-gray-300 flex justify-between`;
const Label = tw.p`block font-medium mx-2 pt-2`;
const ClearIcon = tw.p`block font-medium mx-2 pt-3`;

export default ESign;
