import React, { useEffect, useState } from "react";
import { Images } from "../Images";

const AadharLoading = ({ width, height, color, noPadding = false }) => {
    const [loadingDimensions, setLoadingDimensions] = useState({
        width: 100,
        height: 100,
    });
    const [loadingText, setLoadingText] = useState("Verifying ID...");

    useEffect(() => {
        const isMobile = window.innerWidth < 768;
        if (isMobile) setLoadingDimensions({ width: 70, height: 70 });

        const timer1 = setTimeout(() => {
            setLoadingText("Almost done...");
        }, 2000);


        return () => {
            clearTimeout(timer1);
        };
    }, []);

    return (
        <div className={`grid place-items-center ${noPadding ? "" : "py-28"}`}>
            <img
                src={Images.LoadingGif}
                alt="Loading..."
                height={height || loadingDimensions.height}
                width={width || loadingDimensions.width}
            />
            <p className="mt-4 text-lg font-semibold text-white">{loadingText}</p>
        </div>
    );
};

export default AadharLoading;
