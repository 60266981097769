import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ekyc from "./Pages/Ekyc";
import CompleteProfile from "./Pages/CompleteProfile";
import ESign from "./Pages/Esign";
import Payment from "./Pages/Payment";
import NotFoundModel from "./Components/NotFoundModel";
import Receipt from "./Pages/Receipt";

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />

      <Routes>
        <Route path="/:token" element={<Ekyc />} />
        <Route path="/complete-profile" element={<CompleteProfile />} />
        <Route path="/rent-agreement" element={<ESign />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/receipt" element={<Receipt />} />
        <Route path="*" element={<NotFoundModel />} />
      </Routes>
    </>
  );
}

export default App;
